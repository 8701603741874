import * as React from 'react';
import s from './Avatar.scss';
interface IAvatarProps {
  image: string;
}

export const Avatar = ({ image }: IAvatarProps) => {
  const renderAvatar = () => {
    return (
      <>
        <span className={s.avatar}>
          <span className={s.avatar__media}>
            <img src={image} />
          </span>
        </span>
      </>
    );
  };
  
  return (
    <>
      {renderAvatar()}
    </>
  );
};